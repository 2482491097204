import request from '@/axiosConfig'

/**
 * 登陆
 * @param data
 */
export const doLogin = (data: object) => {
    return request({
        url: '/platform/manager/login',
        method: 'post',
        data
    })
};

/**
 * 修改密码
 * @param password
 */
export const changePassword = (password: string) => {
    return request({
        url: '/platform/manager/changePass',
        method: 'post',
        data: {pass: password}
    })
};

/**
 * 退出
 */
export const exit =() => {
    return request({
        url: '/platform/manager/logout',
        data: {}
    })
};
