

















































































import store from '@/store';
import md5 from "md5";
import {changePassword, exit} from '@/request/Login'

export default {
    name: 'Index',
    data() {
        let self: any =this;
        return {
            isCollapsed: false,
            openNames: [],
            menuList: [],
            tabs: [{comp: 'home', name: '主页', closable: false}],
            childMenus: [],
            currentTabIndex: 0,
            max: 15,
            userInfo: {},
            passwordModal: {
                show: false,
                loading: true,
                pass: '',
                rePass: '',
                ok() {
                    if (self.passwordModal.pass.trim().length < 6) {
                        self.$Message.warning('密码不能少于6位');
                        self.$nextTick(() => self.passwordModal.loading =false);
                        return;
                    }
                    if (self.passwordModal.pass !== self.passwordModal.rePass) {
                        self.$Message.warning('两次密码不一致');
                        self.$nextTick(() => self.passwordModal.loading =false);
                        return;
                    }
                    changePassword(md5(self.passwordModal.rePass)).then((body: any) => {
                        self.passwordModal.loading = false;
                        if (body['code'] ===0) {
                            self.passwordModal.show =false;
                            self.passwordModal.pass='';
                            self.passwordModal.rePass='';
                        }
                    })
                }
            }
        }
    },
    computed: {
        syncComponent() {
            let self: any = this;
            let tab = self.tabs[self.currentTabIndex];
            store.commit('currentComp', tab);
            console.log(`路由：${tab.comp}`);
            return tab.comp
        },
        menuitemClasses() {
            let self: any = this;
            return [
                'menu-item',
                self.isCollapsed ? 'collapsed-menu' : ''
            ]
        },
        rotateIcon() {
            let self: any = this;
            return [
                'menu-icon',
                self.isCollapsed ? 'rotate-icon' : ''
            ];
        },
        comps() {
            let self: any = this;
            let comp: string[] = self.tabs.map((j: any) => j.comp);
            if (comp.length > self.max) comp.shift();
            return comp;
        }
    },
    created(): void {
        let self: any = this;
        let info = sessionStorage.getItem(store.getters.info);
        if (info) {
            let infoObj: any = JSON.parse(info);
            self.userInfo =infoObj;
            self.menuList = infoObj.menuFuncs;
            self.menuList.forEach((j: any) => {
                j.childs.forEach((k: any) => {
                    let comp: string = `${j.key}${k.key}`.replace(new RegExp('/', 'g'), '_');
                    if (comp.startsWith('_')) {
                        comp = comp.substring(1);
                    }
                    if (comp.endsWith('_')) {
                        comp = comp.substring(0, comp.length - 1);
                    }
                    k.comp = comp;
                    self.childMenus.push({
                        comp: comp,
                        name: k.name,
                        ops: k.childs,
                        closable: true
                    })
                })
            });
            sessionStorage.setItem(store.getters.ops, JSON.stringify(self.childMenus));
        }
    },
    methods: {
        to(comp: string) {
            let self: any = this;
            let tabs: any = self.tabs.filter((j: any) => j.comp === comp);
            if (tabs.length > 0) {
                self.currentTabIndex = self.tabs.indexOf(tabs[0]);
                return;
            }
            let tabs2 = self.childMenus.filter((j: any) => j.comp === comp);
            if (tabs2.length > 0) {
                self.tabs.push(tabs2[0]);
                self.currentTabIndex = self.tabs.length - 1;
            }
        },
        currentTabChanged(index: number) {
            let self: any = this;
            self.currentTabIndex = index;
        },
        collapsedSider () {
            let self: any = this;
            self.$refs['left-sider'].toggleCollapse();
        },
        beforeRemove(index: number) {
            let self: any = this;
            if (index <= self.currentTabIndex) {
                self.currentTabIndex --;
            }
            self.tabs.splice(index, 1);
            return new Promise(() => {});
        },
        personInfo(value: string) {
            let self: any =this;
            switch (value) {
                case 'pass':
                    self.passwordModal.show=true;
                    break;
                case 'shouce':
                    window.open('http://docs.cdslhd.com/docs/');
                    break;
                case 'exit':
                    exit().then((body: any) => {
                        if (body['code'] ===0) {
                            sessionStorage.removeItem(store.getters.info);
                            self.$router.replace('/')
                        }
                    });
                    break;
            }
        }
    }
}
